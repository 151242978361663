export interface Api_Interaction_Option {
  text: string
  is_correct: boolean
  confetti: string
}

export enum Api_Video_InteractionTypeEnum {
  QUESTION = 'question',
  POLL = 'poll',
  APPOINTMENT = 'appointment',
  QUIZ = 'quiz',
  GIVEAWAY = 'giveaway',
  TRIVIA_GIVEAWAY = 'trivia_giveaway',
  TRIVIA_GIVEAWAY_QUESTIONS = 'trivia_giveaway_questions',
  TRIVIA_GIVEAWAY_ANSWERS = 'trivia_giveaway_answers',
  LINK = 'link',
}

export interface Api_Interaction_Common {
  collect_email: 'required' | 'optional'
  collect_name: 'required' | 'optional'
  collect_phone: 'required' | 'optional'
  id: string
  interactive_url: string | null
  live_stream_id: string
  prompt: string
  sort_id: number
  video_id: string
  interaction_type: Api_Video_InteractionTypeEnum
}

export interface Api_Interaction_With_Options extends Api_Interaction_Common {
  options: Api_Interaction_Option[]
}
export interface Api_Poll_Interaction extends Api_Interaction_With_Options {
  tally: number
}

export interface Api_Giveaway_Interaction extends Api_Interaction_With_Options {
  giveaway_celebration_emoji: string
  giveaway_description?: string | null
  interaction_type: Api_Video_InteractionTypeEnum.GIVEAWAY
  terms_and_conditions_url: string
  interaction_results?: { id: string; name: string }[]
}

export interface Api_Link_Interaction
  extends Omit<Api_Interaction_Common, 'interactive_url'> {
  interactive_url: string
}

export type Api_Interaction =
  | Api_Interaction_Common
  | Api_Giveaway_Interaction
  | Api_Link_Interaction
  | Api_Trivia_Giveaway_Interaction
  | Api_Poll_Interaction

export type Api_Trivia_Giveaway_Question_Option = {
  text: string
  is_correct?: boolean
}
export type Api_Trivia_Giveaway_Question = {
  id: string
  prompt: string
  options: Api_Trivia_Giveaway_Question_Option[]
}
export interface Api_Trivia_Giveaway_Interaction
  extends Omit<
    Api_Interaction_Common,
    | 'interaction_type'
    | 'tally'
    | 'giveaway_celebration_emoji'
    | 'collect_name'
    | 'collect_phone'
    | 'interactive_url'
  > {
  interaction_type:
    | Api_Video_InteractionTypeEnum.TRIVIA_GIVEAWAY
    | Api_Video_InteractionTypeEnum.TRIVIA_GIVEAWAY_QUESTIONS
    | Api_Video_InteractionTypeEnum.TRIVIA_GIVEAWAY_ANSWERS
  option_sets: Api_Trivia_Giveaway_Question[]
  terms_and_conditions_url: string
  show_answers: boolean
  winners?: string[]
}
export interface IAPI_Create_VideoInteractionResult {
  tally?: Record<string | 'total', number>
}

export type Api_Interaction_Result_Error = {
  data?: {
    error:
      | 'already_responded'
      | 'trivia_giveaway_not_open'
      | 'trivia_giveaway_closed'
      | 'already_sending'
  }
}
