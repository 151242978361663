import type { UID } from 'agora-rtc-sdk-ng'

import type {
  Api_Interaction,
  Api_Product,
  Api_Trivia_Giveaway_Question,
} from '@core/api'

export enum Enum_LiveStream_Provider {
  AGORA = 'agora',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  IVS = 'ivs',
  MUX = 'mux',
  TIKTOK = 'tiktok',
  TWITCH = 'twitch',
  YOUTUBE = 'youtube',
}

export enum Enum_LiveStream_Status {
  IDLE = 'idle',
  ACTIVE = 'active',
  PAUSED = 'paused',
  REPLAY = 'replay',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  DISABLED = 'disabled',
  ERRORED = 'errored',
}

export enum Enum_LiveStream_User_type {
  VIEWER = 'viewer',
  HOST = 'host',
  MODERATOR = 'moderator',
  BOT = 'bot',
  AI_ASSISTANT = 'ai_assistant',
}

export enum Enum_LiveStream_Private_Messaging {
  ALWAYS_ON = 'always_on',
  REPLAY_ONLY = 'replay_only',
  DISABLED = 'disabled',
}

export type Api_Livestream_Entity_Type =
  | 'product'
  | 'poll'
  | 'question'
  | 'giveaway'
  | 'giveaway_collection'
  | 'link'
  | 'trivia_giveaway'
  | 'trivia_giveaway_questions'
  | 'trivia_giveaway_answers'
export enum Enum_LiveStream_Auto_Response {
  ALWAYS_ON = 'always_on',
  MODERATED = 'moderated',
  DISABLED = 'disabled',
}

export interface Api_LiveStream_Entity {
  entity_type?: Api_Livestream_Entity_Type
  entity_id?: string
  tally?: Record<'total' | string, number>
  winners?: string[]
}

export interface Api_LiveStream_Entity_Action {
  action_type?:
    | 'highlight_entity'
    | 'unhighlight_entity'
    | 'disable_entity'
    | 'enable_entity'
    | 'giveaway_collection'
  entity_type?: Api_Livestream_Entity_Type
  entity_id?: string
  entities?: Api_LiveStream_Entity[]
  message_id?: string
  text?: string
  username?: string
  tally?: Record<'total' | string, number>
  winners?: string[]
  option_sets?: Api_Trivia_Giveaway_Question[]
}

export interface Api_LiveStream_Message {
  event_type:
    | 'message'
    | 'private_message'
    | 'join'
    | 'leave'
    | 'heart'
    | 'pin_message'
  id: string
  text?: string
  user_type: Enum_LiveStream_User_type
  username?: string
  elapsed_time?: number
  reply?: {
    message_id: string
    text: string
    username: string
  } | null
  visibility?: 'public' | 'private'
}

/**
 * Chat messages received from API (not WS)
 */
export interface Api_LiveStream_Chat_Message {
  elapsed_time: number
  guest_id: string
  id: string
  inserted_at: string
  original_text: string
  replay_id: number
  reply: {
    message_id: string
    text: string
    username: string
  } | null
  status: string // "approved"
  text: string
  user_id: number | null
  user_type: Enum_LiveStream_User_type
  username: string
  visibility?: 'public' | 'private'
}

export interface Api_LiveStream_PinnedMessage {
  live_stream_id: string
  message_id: string
  text: string
  username: string
}

export interface Api_LiveStream_Chat_Message_Collection {
  entries: Api_LiveStream_Chat_Message[]
  next_page: string | null
}

export interface Api_LiveStream_Event_Collection {
  entries: Api_LiveStream_API_Event[]
  next_page: string | null
}

export interface Api_LiveStream_State_Snapshot {
  elapsed_time?: number
  replay_id?: string
  state_snapshot?: string
  version?: number
}

export interface Api_LiveStream_API_Event {
  action_id: string
  action_type: string
  elapsed_time: number
  guest_id: string | null
  live_stream_id?: number
  payload: Api_LiveStream_Entity_Action | null
  user_id: number | null
  user_type: Enum_LiveStream_User_type
  username?: string
  replay_id: number
}

export interface Api_LiveStream_Event {
  event_type: string
  id: string
  payload: Api_LiveStream_Entity_Action | null
  user_type: Enum_LiveStream_User_type
  elapsed_time: number
}

export interface Api_LiveStream_Running_Announcement {
  event_type: 'update_announcement'
  live_stream_id: string
  announcement: string
}

export interface AgoraProviderInfo {
  // Representing the order of the streamers in the live stream.
  active_streamers_uids?: UID[]

  active_streamers?: Array<{
    parent_uid?: UID
    uid: UID
    username: string
    visitor_id?: string
  }>

  // Subscriber Agora token.
  token?: string

  // Agora UID is shared by viewer and guest speaker roles.
  uid: UID
}

export interface Api_LiveStream_Create_Interaction {
  event_type: 'create_interaction'
  id: string
  interaction: Api_Interaction
  live_stream_id: string
}

export interface Api_LiveStream_Flash_Deal_Listing {
  coupon_code: string
  deal_listing_id: string
  discount_amount: string
  discount_type: 'percentage' | 'fixed_amount'
  product_ext_id: string
  product_id: string
}

export interface Api_LiveStream {
  announcement?: string
  blocked_viewers?: string[]
  business_privacy_policy_url?: string
  chat_enabled: boolean
  chat_in_replay_enabled: boolean
  chat_moderation_enabled: boolean
  countdown_started_at?: string
  deals: Api_LiveStream_Flash_Deal_Listing[]
  disabled_entities: Api_LiveStream_Entity[]
  ended_at: string
  ended_polls?: string[]
  event_name?: string
  highlighted_entities?: Api_LiveStream_Entity[]
  highlighted_entity: Api_LiveStream_Entity | null
  host_username?: string | null
  interactions: Api_Interaction[]
  open_entry_giveaway?: string
  pinned_message?: Api_LiveStream_PinnedMessage | null
  playback_url: string
  private_messaging?: Enum_LiveStream_Private_Messaging
  products: Api_Product[]
  provider: Enum_LiveStream_Provider
  provider_info?: AgoraProviderInfo
  reconnect_grace_period?: number
  replay_enabled: boolean
  replay_messaging_enabled: boolean
  replay_url: string | null
  scheduled_at: string
  started_at?: string | null
  status: Enum_LiveStream_Status
  scheduled_end_at?: string
  test_mode?: boolean
  terms_and_conditions?: string
  username?: string // websocket only
  username_info?: {
    is_hydrated: boolean
    username: string
  } // websocket only
  user_type?: string // websocket only
  video_id: string
  viewers_count?: number
  viewers_count_enabled: boolean
  hearts_count?: number
  hearts_count_enabled?: boolean
  web_share_url: string
}

export interface Api_LiveStream_Message_Rate_Limit {
  limit: number
  milliseconds: number
}
